.input-group.iconed {
  align-items: center;

  > i:first-of-type {
    align-self: center;
    position: absolute;
    z-index: 7;
    left: 1em;
  }

  > input:first-of-type {
    padding-left: 2.5em;
  }
}

.icon-button {
  @extend .animated;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    @extend .text-primary;
    text-shadow: 0 0 .1em rgba(0, 0, 0, .3);
  }
}

.form-as-info {
  .ng-clear-wrapper,
  .ng-arrow-wrapper {
    opacity: 0;
  }

  *[disabled] {
    background-color: transparent;
  }

  ng-select,
  ng-select * {
    background-color: transparent !important;
  }
}

/* Helpers */
.my-show {
  opacity: 1;
}

.my-hide {
  opacity: 0;
  pointer-events: none;
}

/*  Global helpers */
@mixin animated($what: all, $for: .1s) {
  transition: $what $for ease-in;
}
@mixin animated-long($what: all, $for: .3s) {
  transition: $what $for ease-in;
}

.animated {
  @include animated();
}

.animated-long {
  @include animated-long(all);
}

.disabled {
  pointer-events: none;
  user-select: none;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-x {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.hyphens {
  hyphens: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-control-label {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-overflow-ellipsis-title {
  @extend .text-overflow-ellipsis;

  &::after {
    content: attr(title);
  }
}

@mixin shadow-sharp($alpha: .4) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, $alpha) !important;
}

.shadow-sharp {
  @include shadow-sharp();
}

/* common styles imports */
@import 'btn-navbar';
@import 'loader';

/* node_modules imports */

// https://github.com/scttcper/ngx-toastr/issues/602
#toast-container > div {
  opacity: 1;
}

@import './toastr-bs5-alert';
@import 'drawer';
@import 'picker';
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
