app-login {
  @extend .row;
  @extend .justify-content-center;
  background: linear-gradient(45deg, rgba(147, 149, 152, 0) 30%, rgba(147, 149, 152, 1) 100%);
  height: 100%;
  margin: 0;

  .login-container {
    @extend .mt-5;
    max-width: 30rem;
  }

  input:-webkit-autofill {
    box-shadow: inset 0 0 0 9999px white !important;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .07em;
    font-variation-settings: 'wght' 500;
  }

  .login-content.card {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 0 1px rgba(0, 0, 0, .08) !important;
  }

  .btn-primary {
    &:focus,
    &:hover {
      box-shadow:
        0 14px 26px -12px rgba(19, 41, 75, .2),
        0 4px 23px 0 rgba(19, 41, 75, .2),
        0 8px 10px -5px rgba(19, 41, 75, .2) !important;
    }
  }

  .input-wrapper {
    @extend .mb-3;
    display: flex;
    align-items: center;

    > i {
      @extend .text-muted;
      width: 3rem;
      text-align: center;
      position: absolute;
    }

    > input {
      padding-left: 3rem !important;
    }
  }

  .login-btn {
    @extend .btn;
    @extend .btn-primary;
    width: 100%;
    font-size: 14px;
    padding: 15px;
    border: 0;
    background-color: #13294b !important;
    color: #fff !important;
  }
}
