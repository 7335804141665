app-sidebar-menu-item {
  -webkit-user-drag: element;

  .submenu {
    @extend .m-0;
    @extend .ps-1;
    background-color: rgba(0, 0, 0, .03);
    transition: all .3s ease-in;
    //box-shadow: inset 1px 0 2px rgba(0, 0, 0, .3);
    overflow: hidden;
  }

  a {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  li {
    list-style: none;

    a {
      @extend .text-secondary;
      @extend .animated;
      padding: 10px 25px 10px 15px;
      display: flex;
      align-items: center;

      &.has-arrow {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 7px;
          height: 7px;
          border-width: 1px 0 0 1px;
          border-style: solid;
          border-color: #607d8b;
          right: 1em;
          transform: rotate(135deg) translate(0, -50%);
          transform-origin: top;
          top: 47%;
          transition: all .3s ease-out;
        }
      }
    }
  }

  li.active {
    > a {
      @extend .text-primary;
      font-weight: 600;
    }
  }

  .active.has-arrow {
    &::after {
      transform: rotate(-135deg) translate(0, -50%);
    }
  }


  .fa.fa-angle-up {
    transition: all .3s ease-in;
  }

  .is-opened .fa.fa-angle-up {
    transform: rotate(-180deg);
  }
}
