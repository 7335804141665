@import 'points/point/point.component';
@import 'points/point-info/point-info.component';

.map-navbar {
  display: flex;
  flex-grow: 1;

  ng-select.ng-select .ng-value-container {
    position: relative;
    margin-left: 2rem;
  }
}

app-map {
  svg {
    overflow: visible;
    user-select: none;
    pointer-events: fill;
    touch-action: none;
  }

  // rewrite tooltip inner styles to place content
  .tooltip-advice {
    max-width: 400px;
    width: 100%;
    min-width: 200px;

    &.show {
      opacity: 1;
    }

    .tooltip-inner {
      background-color: $secondary;
      color: $white;
      line-height: 1rem;
      padding: .5rem;
    }

    .arrow::before {
      border-right-color: $secondary !important;
    }
  }

  .map-and-controls {
    display: flex;
    height: 100%;
  }

  .map-wrapper {
    display: flex;
    height: 100%;
    width: 1px;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 1rem;

    &.editing > div {
      cursor: pointer;
    }

    @at-root .edge .map-wrapper svg {
      max-height: 100%;
    }

    svg {
      &.cursor-hand {
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }
    }
  }

  .hy-drawer-content {
    @extend .border-left;
    height: calc(100% - #{$navbar-height});
    display: flex;
    flex-direction: column;

    .workplaces-list {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 1rem;
      overflow: auto;
    }

    app-point-position {
      order: -1;
    }
  }

  .controls {
    @extend .animated;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    .controls-font {
      font-size: rfs-fluid-value(.7rem);
    }

    .floor-selection {
      display: grid;
      grid-template-areas: '_V' '_8c' '_7c' '_6c';

      ._6b {
        grid-area: _6b;
      }

      ._6c {
        grid-area: _6c;
      }

      ._7b {
        grid-area: _7b;
      }

      ._7c {
        grid-area: _7c;
      }

      ._8c {
        grid-area: _8c;
      }
    }

    .zoom {
      display: flex;
      flex-direction: column;
      height: 5rem;
      justify-content: space-between;
    }
  }

  .point-search-applied app-point-info {
    opacity: .5;

    &.point-search-match {
      order: -1;
      opacity: 1;
    }
  }

  .map-toaster {
    position: absolute;
    bottom: 1rem;
    transform: translateY(.5rem);
    height: 4rem;
    min-width: 14rem;
    left: 1rem;
    z-index: 1050;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    background-color: $secondary;
    border-radius: .2rem;
    color: $white;
    box-shadow: 0 0 .5rem rgba(0, 0, 0, .1);
    transition: all .15s ease-in-out;
  }

  .map-wrapper:hover .map-toaster-shown {
    opacity: 1;
    visibility: initial;
    transform: translateY(-.25rem);
  }
}
