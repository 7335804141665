
app-sections-pages {
  .hy-drawer-content {
    @extend .border-left;
    height: calc(100% - #{$navbar-height});
    display: flex;
    flex-direction: column;

    .pages-list {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 1rem;
      overflow: auto;

    }

  }
}
