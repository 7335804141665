app-section-product-page {
  .description {
    img {
      width: 100%;
    }
  }

  app-faq {
    .edit {
      cursor: pointer;
    }

    .edit:hover {
      color: black !important;
    }

    img {
      width: 100%;
    }
  }

  span.open-eye {
    &:hover {
      i.fa-eye {
        display: none;
      }

      i.fa-eye-slash {
        display: inline-block !important;
      }
    }
  }

  span.close-eye {
    i.fa-eye {
      display: none;
    }

    &:hover {
      i.fa-eye-slash {
        display: none;
      }

      i.fa-eye {
        display: inline-block !important;
      }
    }
  }

}
