$table-background: #fff !default;

app-pagination-wrapper,
app-table,
app-table-with-controls {
  overflow: auto;
  height: 100%;
}

.nowrap {
  white-space: nowrap;
}

@import './pagination-wrapper/pagination-wrapper.component';
@import './paginator/paginator.component';
@import './table/table.component';
@import './footer-wrapper/footer-wrapper.component';
