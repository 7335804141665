$navbar-height: 1px !default;

.router-container app-passes-table {
  flex-direction: row !important;

  app-default-crud-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }

  app-drawer .hy-drawer-content {
    @extend .shadow-sm !optional;

    .aside-form {
      overflow-y: auto;
      max-height: calc(100vh - #{$navbar-height});
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }
  }
}
