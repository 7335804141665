$pagination-height: 60px;

app-main-layout {
    @extend .d-flex;
    height: 100%;

    app-navbar {
        z-index: 11;
    }
}

.router-container {
    @extend .d-flex;
    @extend .flex-grow-1;

    > :not(router-outlet) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: calc(100vh - #{$pagination-height});
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
    }

    > :not(router-outlet) {
        app-banners-widget {
            overflow-y: visible !important;
        }
    }
}

.main-view-wrapper {
    @extend .flex-grow-1;
    position: relative;
}

.main-view {
    position: absolute;
    @extend .d-flex;
    @extend .flex-column;
    width: 100%;
}
