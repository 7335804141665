.loader {
    display: inline-block;
    width: 64px;
    height: 64px;
    z-index: 1000;
}

.loader::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    transform: scale(1.1);
    border-radius: 1em;
}

.loader::after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 9px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: loader 1.2s linear infinite;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
