app-sections-pages {
  .root-folder {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 18;
    background-color: #FFFFFF;
    overflow-x: hidden;

    .card {
      transition: 1s;

      &:hover {
        @extend .bg-primary;
        * {
          @extend .text-white;
        }
        .fa {
          @extend .text-white;
        }
      }
    }
  }

}
