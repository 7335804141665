@import '@progress/kendo-theme-default/scss/treeview/index';
@import '@progress/kendo-theme-default/scss/treeview/theme';
@import '@progress/kendo-theme-default/scss/treeview/layout';

// stylelint-disable

$lines-color: $secondary;

.vertical-line {
    //content: '';
    //position: absolute;
    //left: 25px;
    //height: calc(100% - 32px);
    //width: 2px;
    //background-color: $lines-color;
}

.horizontal-line {
    //content: '';
    //position: absolute;
    //left: 0;
    //transform: translate(-7px, calc(50% - 1px));
    //height: 2px;
    //width: 7px;
    //background-color: $lines-color;
}

.hide-vertical-line {
    //content: '';
    //position: absolute;
    //width: 2px;
    //height: calc(100% - 32px);
    //top: 24px;
    //left: 9px;
    //background-color: $body-bg;
}

app-employees-tree-view {
    .k-treeview-toggle {
        margin-inline-start: initial;
    }
    //ul {
    //    //padding-left: 2rem;
    //    li.k-treeview-item {
    //
    //
    //        //.k-treeview-leaf {
    //        //    z-index: 1;
    //        //}
    //        //
    //        //position: relative;
    //        //
    //        //&::before {
    //        //    @extend .vertical-line;
    //        //}
    //        //
    //        //&:not([aria-level='1']) {
    //        //    .k-treeview-leaf::before {
    //        //        @extend .horizontal-line;
    //        //    }
    //        //
    //        //    &:last-child::after {
    //        //        @extend .hide-vertical-line;
    //        //    }
    //        //}
    //    }
    //}


    .matched-scroll-to {
        p,
        a,
        i,
        .employees-tree-view__person {
            color: white !important;
            text-shadow: 0 0 3px rgba(0, 0, 0, .3);
        }
    }

    p,
    a,
    i,
    .employees-tree-view__person > div {
        @extend .text-overflow-ellipsis-title;
    }

    i {
        text-overflow: initial;
    }

    .contacts {
        p,
        a {
            text-overflow: ellipsis;
        }

        i {
            @extend .text-grey;
        }

        p {
            overflow: hidden;
            margin-right: 1rem;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            i {
                margin-right: .3rem;
            }

            &.internal {
                width: 5rem;
            }

            &.mobile {
                width: 12em;
            }

            &.email {
                width: 17rem;
            }
        }

        a {
            text-decoration: underline;
        }
    }

    kendo-treeview.employees-tree-view {
        padding: 1rem;
        height: 100%;

        .k-treeview-leaf {
            cursor: pointer;
            background: #fff;
            padding: 0;
            border-radius: 5px;
            box-shadow: 0 3px 13px -2px rgba(0, 0, 0, .1);
            margin-bottom: 10px;
            transition: all .15s ease-in-out;

            &:hover {
                background-color: #fff;
            }

            &.k-focus {
                box-shadow: none;
                outline: none;
            }
        }

        .k-icon {
            display: none;
        }

        .k-treeview-mid {
            &:hover {
                & .k-treeview-leaf,
                & .k-icon {
                    transform: translate(2px);
                }

                & .employees-tree-view__info {
                    &::before {
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    .employees-tree-view {
        &__info {
            position: relative;
            padding: 5px 1rem;
            line-height: 18px;
            display: flex;
            align-items: center;
        }

        &__person {
            margin-right: 20px;
            width: 20rem;
        }

        &__name {
            font-size: 16px;
            color: #353b48;
        }

        &__position {
            font-size: 12px;
            color: #95afc0;
        }

        &__button {
            margin: -1rem;
            padding: 1rem;
            margin-right: 0;

            .fa {
                transform: scale(1.2);
            }
        }

        @at-root .matched-scroll-to {
            background-color: #ff6358;
            border-radius: 5px;

            & .employees-tree-view__name,
            & .employees-tree-view__position,
            & .fa-id-badge {
                color: #fff;

                &:hover {
                    color: #fff;
                }
            }
        }

        .fa-arrow-right {
            transition: transform .1s ease-in;
        }

        .expanded .fa-arrow-right {
            transform: rotate(90deg);
        }
    }
}
