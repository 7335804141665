app-control-cell {
  display: inline;
  @extend .justify-content-around;
  @extend .align-items-center;

  i {
    visibility: hidden;
    opacity: 0;
    transition: all .1s ease-in;
    cursor: pointer;

    &:hover {
      color: #455a64;
    }
  }
}

tr:hover app-control-cell i {
  visibility: visible;
  opacity: 1;
}
