app-wall-write-post {
  div.comment {
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(19, 41, 75, 0.05);

    textarea.form-control {
      border: none;
      background-color: rgba(19, 41, 75, 0);
      resize: none;

      &:focus {
        background-color: rgba(19, 41, 75, 0);
        border: none;
      }
    }

    button.btn,
    .btn-link,
    .btn-sm,
    .text-no-capitalize {
      padding: 5px 10px;
      border-radius: 10px;

      i {
        margin-left: 5px;
      }
    }
  }

}
