app-navbar {
    ul.navbar-nav {
        li:empty {
            display: none;
        }
    }

    .nav-padding {
        .btn {
            height: 40px;
            // display: block;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // white-space: nowrap;
            text-decoration: none !important;
            position: relative;
        }

        .dont-hide {
            @extend .position-absolute;
            // @extend .top-0;
            @extend .start-100;
            @extend .translate-middle;
            @extend .badge;
            @extend .rounded-pill;
            @extend .bg-danger;

            z-index: 10;
            top: ($spacer / 4);
        }

        &.only-icon {
            i+* {
                display: none;
            }
        }

        app-searchbar {
            min-width: ($spacer * 18);
        }

        @media screen and (max-device-width: 960px) {
            padding: var(--#{$prefix}nav-link-padding-y) var(--#{$prefix}nav-link-padding-x);

            app-searchbar {
                min-width: 100% !important;
            }
        }
    }
}

// app-navbar {
//   @extend .navbar;
//   @extend .navbar-expand-lg;
//   @extend .navbar-light;
//   @extend .navbar-height;
//   @extend .shadow-sm;
//   background: $navigation-background;
//   z-index: 19;
//   align-items: stretch;
//   justify-content: space-between;
//   flex-shrink: 0;

//   .navbar-nav,
//   nav.navbar {
//     flex-direction: row;
//   }

//   .dropdown-toggle::after {
//     display: none;
//   }

//   .navbar-toggler {
//     @extend .mx-3;
//     @extend .px-0;
//     @extend .navbar-height;
//     align-self: flex-start;
//   }

//   >ul {
//     @extend .navbar-nav;
//     @extend .mx-3;
//   }

//   .dropdown-menu {
//     position: absolute !important;
//   }
// }

// .navbar-height {
//   min-height: $navbar-height;
// }


// app-navbar {
//   @extend .navbar;
//   @extend .navbar-expand-lg;
//   @extend .navbar-light;
//   @extend .navbar-height;
//   @extend .bg-white;
//   @extend .shadow-sm;
//   @extend .p-0;
//   z-index: 19;
//   flex-wrap: wrap;
//   align-items: stretch;
//   justify-content: flex-end;

//   &.semi-transparent {
//     background-color: rgba(239, 226, 226, 0.1) !important;

//     * {
//       color: white !important;
//     }
//   }

//   .navbar-toggler {
//     @extend .me-0;
//     outline: none;
//   }

//   .navbar-nav,
//   nav.navbar {
//     flex-direction: row;
//   }


   .profile-pic {
     @extend .shadow-sm;
     width: 40px;
     height: 40px;
     cursor: pointer;
     border-radius: 100%;
     // border: 1px rgba(0, 0, 0, .3) solid;
     background: rgba(0, 0, 0, .3);
     margin: 0;
     position: relative;
     overflow: hidden;

     app-user-photo {
       width: 40px;
       height: 40px;

       &.error {
         top: 0;
         opacity: 0;
       }
     }
   }

//   .dropdown-toggle::after {
//     display: none;
//   }

//   .dropdown-menu {
//     position: absolute !important;
//   }

//   >*:last-child {
//     margin-right: 1rem;
//   }

//   .ru_position_name {
//     @extend .text-muted;
//     width: 1px;
//     flex-grow: 1;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }

// .navbar-toggler+app-searchbar {
//   @extend .ms-3;
// }
