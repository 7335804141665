app-wall-content-controls {
  button.btn,
  .btn-secondary,
  .btn-sm,
  .text-no-capitalize {
    padding: 5px 10px;
    border-radius: 10px;
  }
}


