app-news-widget {
  .card {
    max-height: 250px;
    overflow: hidden;
    cursor: pointer;
    transition: 1s;
    position: relative;
    container-name: news-card;
    container-type: inline-size;

    &:hover {
      .card-img {
        filter: brightness(0.8);
        transform: scale(1.2);
      }
    }

    .card-img {
      height: 250px;
      object-fit: cover;
      filter: brightness(0.55);
      transition: 1s;
    }

    .news-controls {
      left: 0;
      bottom: 0
    }
  }
}

@container news-card (width < 260px) {
  .card-title {
   @include font-size(1.1rem);
 }
}