$resizer-width: 2px !default;
$resizer-toggable-width: 20px !default;
$resizer-background: $primary !default;
$resizer-z-index: 1 !default;

app-resizer {
  height: 100%;
  cursor: ew-resize;
  position: absolute;
  z-index: $resizer-z-index;

  &::before {
    @extend .animated;
    content: '';
    position: absolute;
    width: $resizer-width;
    background: $resizer-background;
    height: 100%;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    left: -$resizer-toggable-width * 0.5;
    width: $resizer-toggable-width;
    height: 100%;
  }
}
