@import '~ng-pick-datetime/assets/style/picker.min.css';

.cdk-overlay-container {
  z-index: 1150;
}

.cdk-global-scrollblock {
    overflow: hidden !important;
}

.owl-dt-container {
  .owl-dt-calendar-cell-selected {
    background-color: $primary !important;
  }

  .owl-dt-container-info {
    color: $secondary;
  }

  .owl-dt-container-info-active {
    color: $dark;
  }

  .owl-dt-calendar-cell-content {
    font-size: rfs-fluid-value(1em);
  }

  .owl-dt-calendar-cell-in-range {
    background-color: rgba($primary, .1) !important;
  }
}

.picker-extension-right .owl-dt-container-inner {
  display: flex;
}
