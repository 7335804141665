@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.cdk-overlay-container {
    z-index: 1150;
}

.cdk-global-scrollblock {
    overflow: hidden !important;
}

.owl-dt-container {
    .owl-dt-calendar-cell-selected {
        background-color: $primary !important;
    }

    .owl-dt-container-info {
        color: $secondary;
    }

    .owl-dt-container-info-active {
        color: $dark;
    }

    .owl-dt-calendar-cell-content {
        font-size: rfs-fluid-value(1em);
    }

    .owl-dt-calendar-cell-in-range {
        background-color: rgba($primary, .1) !important;
    }
}

.picker-extension-right .owl-dt-container-inner {
    display: flex;
}

app-date-selector {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    input {
        @media (max-width: 768px) {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem;
        }
    }

    >i:first-of-type {
        align-self: center;
        position: absolute;
        z-index: 7;
        left: 1em;
    }

    >input:first-of-type {
        padding-left: 2.5em;
    }


    .date-change {
        z-index: 3;
        align-self: center;
        position: absolute;
        right: .25rem;

        >div {
            display: inline-block;
            cursor: pointer;
        }

        i:first-of-type {
            margin-right: .3em;
        }
    }
}
