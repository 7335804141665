$col-cell-width: 40px;
$row-cell-height: 40px;

$col-height: 40px;

$footer-height: 2rem;

$event-default-color: $gray-600;
$event-aprooving-color: $warning;
$event-aprooved-color: $success;
$event-rejected-color: $danger;

// $event-colors: () !default;
$event-colors: (
    "draft": $event-default-color,
    "aprooving": $event-aprooving-color,
    "aprooved": $event-aprooved-color,
    "rejected": $event-rejected-color,
);

app-month-view-component {
    .month {
        @extend .fw-bolder;
        text-transform: capitalize;
        //text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
        //0px 8px 13px rgba(0, 0, 0, 0.1),
        //0px 18px 23px rgba(0, 0, 0, 0.1);
    }

    table {
        border-collapse: collapse;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 14.2857143%;
        height: 2rem;
        text-align: center;
        box-sizing: border-box;
    }

    li.days {
        //@extend .fw-bolder;
        //@extend .bg-secondary;
        //@extend .text-white;
        font-family: $headings-font-family;
        background-color: rgba(0, 0, 0, 0.03);
    }

    .grey {
        //@extend .text-muted
        color: $gray-300;
    }


    .red {
        @extend .text-danger
    }

    .selected {
        background: $event-default-color;
    }

    //border-collapse: collapse;

    .dot {
        height: 10px;
        width: 10px;
        background-color: $success;
        border-radius: 50%;
        display: inline-block;
    }

    td.border-left {
        //box-shadow: -2px 0px 0px 0px rgba(34, 60, 80, 0.2);
        border-left: 1px solid black !important;
        //margin-left:-2px;
    }

    td.border-right {
        //box-shadow: 2px 0px 0px 0px rgba(34, 60, 80, 0.2);
        border-right: 1px solid black !important;
        //margin-right:-2px;
    }

    td.border-top {
        //box-shadow: 0px -2px 0px 0px rgba(34, 60, 80, 0.2);
        border-top: 1px solid black !important;
        //margin-top:-2px;
    }

    td.border-bottom {
        //box-shadow: 0px 2px 0px 0px rgba(34, 60, 80, 0.2);
        border-bottom: 1px solid black !important;
        //margin-bottom:-2px;
    }

    @media (max-width: 576px) {
        td {
            padding: 0.6rem !important;
        }
    }

    td:not(.days):not(.grey):hover {
        cursor: pointer;
        background-color: fade-out($warning, 0.85);
        color: color-contrast(fade-out($warning, 0.85));
    }
}

app-calendar-view-component {
    @media (max-width: 576px) {
        .card-body {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

app-gantt-view-component {

    @extend .shadow-sm;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 1px;

    .scrolling-area {
        @extend table;
        @extend .table;
        @extend .mb-0;

        .scrollable-content {
            width: auto;
            height: auto;
        }

        flex-grow: 1;
        height: 1px;
        contain: strict;
        transform: translateZ(0);
        will-change: scroll-position;

        .header-decoration {
            //@extend .border;
            background: #fff;
            //box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);
            //border-bottom: 1px solid #dee2e6 !important;
        }

        .vertical-scroller {
            margin: 0 auto;
            box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);

            >.scrollable-content {
                display: flex;
            }
        }

        .col-header,
        .cell {
            width: $col-cell-width;
        }

        .row-header,
        .cell {
            height: $row-cell-height;
            position: relative;

            >* {
                width: 100%;
                height: 100%;
            }
        }

        .col-header,
        .row-header,
        .footer-cell {
            @extend th;
            @extend .header-decoration;
        }

        .footer-cell {
            position: sticky;
            bottom: 0;
            height: $footer-height;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .col-header {
            position: sticky;
            top: 0;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            height: $col-height;
        }

        .row-header {
            width: 100%;

            >* {
                padding: 0 .4rem;
            }
        }

        .row-headers-container {
            position: sticky;
            left: 0;
            z-index: 4;

            .fill-cell {
                @extend .header-decoration;
                transform: translate(0) !important;
                position: sticky;
                top: 0;
                z-index: 1;
                height: $col-height;
                width: 100%;
            }
        }

        .cell {
            @extend .border;

            &:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, .05);
            }

            width: $col-cell-width;
            height: $row-cell-height;

        }
    }
}

.vacation {}

@each $color, $value in $event-colors {
    .vacation-#{$color} {
        cursor: pointer;
        background-color: $value !important;
        color: color-contrast($value);
    }
}

@each $color, $value in $event-colors {
    .vacation-gantt-#{$color} {
        cursor: pointer;
        background-color: $value !important;
        height: $row-cell-height*0.5 !important;
        width: $col-cell-width !important;
        left: -1px;
        position: absolute;
    }
}

@each $color, $value in $event-colors {
    .text-vacation-#{$color} {
        color: $value;
    }
}

@each $color, $value in $event-colors {
    .btn-vacation-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color, $value in $event-colors {
    .btn-vacation-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

app-navbar {
    app-year-picker.whithout-change-buttons {
        .change-month-button {
            display: none;
        }
    }
}

.k-in {
    width: 100%;

    .leaf {
        width: 100%;
    }
}

kendo-treeview.employees-tree-view {
    padding: 1rem;
    height: 100%;

    .k-in {
        cursor: pointer;
        background: #fff;
        padding: 0;
        border-radius: 5px;
        box-shadow: 0 3px 13px -2px rgba(0, 0, 0, .1);
        margin-bottom: 10px;
        transition: all .15s ease-in-out;

        &:hover {
            background-color: #fff;
        }

        &.k-state-focused {
            box-shadow: none;
            outline: none;
        }
    }

    .k-icon {
        display: none;
    }

    .k-mid {
        &:hover {

            & .k-in,
            & .k-icon {
                transform: translate(2px);
            }

            & .employees-tree-view__info {
                &::before {
                    border-radius: 4px;
                }
            }
        }
    }
}

.cursor-not-alowed {
    cursor: not-allowed;
}
