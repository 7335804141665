app-footer-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;

  .container, .container-fluid {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  .table-footer {
    // box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);
    @extend .border-top;
    @extend .px-3;
    @extend .py-1;
    width: 100%;
    background: $table-background;
    position: sticky;
    bottom: 0;
    left: 0;
  }
}
