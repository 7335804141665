app-happy-birthday-ilia {
  @import "../../../../../../shared/styles/scrollbar";
  @extend .row;
  background: linear-gradient(45deg, rgba(147, 149, 152, 0) 30%, rgba(147, 149, 152, 1) 100%);
  height: 100%;
  overflow: auto;
  .card {
    border-radius: 7px;
    @extend .shadow-sm
  }

  .embed-responsive {
    border-radius: 7px;
  }

}
