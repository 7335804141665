app-dashboard {
  @extend .p-3;
  overflow-x: hidden;

  app-table-paginator {
    display: flex;
    justify-content: center;

    .count-info {
      display: none;
    }
  }

  app-banners-widget {
    //position: relative;
    .carousel-container {
      margin-top: -76px;
      margin-left: -16px;
      margin-right: -16px;
      //position: absolute;
      height: 450px;
      overflow: hidden;
      //box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
      // border-bottom: 1px solid #999999;
      box-sizing: content-box;

      img {
        height: 450px;
        width: 100%;
        object-fit: cover;
        //-webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
        //filter: blur(2px);
      }

      .carousel-caption {
        width: 100%;
        left: 0;
        background-color: rgba(0, 0, 0, 0.35);
      }

      .item {
        box-sizing: content-box;
      }
    }

    ~ * {
      @extend .pt-3;
    }
  }

  app-birthdays-carousel-widget {
    .users-carousel {
      carousel {
        .person-container {
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          z-index: 1;
          cursor: pointer;
        }

        .carousel-caption {
          width: 100%;
          left: 0;
          //bottom: 0;
          visibility: hidden;
          background-color: rgba(36, 36, 36, 0.49);
        }

        .person-container:hover .carousel-caption {
          visibility: visible;
        }

        .carousel-item {
          margin-right: 0;
        }

        .carousel-control {
          display: none;
        }
      }
    }
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &.active {
      width: 100%;
      animation-name: carouselSlideIn;
    }

    &:not(.active) {
      display: table-cell;
      animation-name: carouselSlideOut;
    }
  }

  @-webkit-keyframes carouselSlideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes carouselSlideOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @import '../../../../../../../shared/styles/scrollbar';

}
