@import '@fortawesome/fontawesome-free/css/all.css';

$primary: #1f2937;
$secondary: #374151;
$warning: #F5DF4D;
$enable-caret: true;
$danger: rgb(225, 6, 0);
$text-muted: #666666;
// Cards =======================================================================
$card-border-width: 0;
$card-border-color: transparent;
// Fonts =======================================================================
@import './assets/fonts/opensans/opensans.css';
@import './assets/fonts/montserrat/montserrat.css';
//$font-size-base: 1.1rem !default; // Увеличен базовый размер шрифта
$font-family-base: 'Open Sans', sans-serif;
$font-weight-base: 400; // Легкий вес шрифта для современного ощущения
$font-weight-bold: 600; // Полужирный для заголовков
$headings-font-family: 'Montserrat', sans-serif;

// Miscellaneous
$body-color: #000;
$body-bg: #fafafa;
// $border-radius-sm: .2rem;
// $border-radius: .3rem;
// $border-radius-lg: .5rem;
//$badge-color: $body-color;

// $input-focus-box-shadow: none;
// $input-box-shadow: none;
// $input-btn-focus-box-shadow: none;
// $input-focus-width: 0;
$input-disabled-bg: $body-bg;
$component-active-bg: lighten($primary, 10%);
$navbar-height: 40px;

@import 'bootstrap/scss/bootstrap';

$iphone-bottom-bar-height: max(1.8rem, env(safe-area-inset-bottom));

@mixin iphone-height {
    height: calc(100svh - $iphone-bottom-bar-height) !important;
}

@mixin iphone-height-without-navbar {
    height: calc(100svh - $iphone-bottom-bar-height - 115px) !important;
}


html,
body {
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;

    @supports (height: 100dvh) {
        height: 100dvh;
    }

    @supports (-webkit-touch-callout: none) {
        @include iphone-height;

        app-sidebar .hy-drawer-content {
            @include iphone-height;
        }

        app-pagination-wrapper,
        app-table,
        app-table-with-controls {
            @include iphone-height-without-navbar;
        }

        app-pagination-wrapper .table-footer {
            bottom: $iphone-bottom-bar-height;
        }
    }

    @supports not (-webkit-touch-callout: none) {}

    width: 100%;
}

.navbar-height {
    height: $navbar-height;
}

@import '../../../shared/styles/index';
@import '../../../shared/styles/select';

.text-no-capitalize {
    text-transform: none !important;
}

.text-dark {
    color: $body-color !important;
}

a {
    color: $primary;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

// Buttons =====================================================================
@mixin ripple($color) {
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        background: $color;
        opacity: 0.6;
        border-radius: 50%;
        transform: scale(0);
        transition: transform 0.4s ease, opacity 0.5s ease;
    }

    &:active::after {
        transform: scale(10);
        opacity: 0;
    }

    &.disabled,
    &[disabled] {
        &::after {
            display: none;
        }
    }
}

@mixin btn($class, $bg, $color) {
    .btn-#{$class} {
        &:focus {
            background-color: $bg;
            box-shadow: 0 0 0 2px rgba(204, 204, 204, .5);
        }

        &:hover,
        &:active:hover {
            background-color: darken($bg, 6%);
        }

        &:active {
            box-shadow: none;
        }

        @include ripple($color);
    }

    .btn-outline-#{$class} {
        @include ripple($color);
    }
}

@include btn(primary, $primary, $white);
@include btn(secondary, $secondary, $gray-500);
@include btn(success, $success, $white);
@include btn(info, $info, $white);
@include btn(warning, $warning, $white);
@include btn(danger, $danger, $white);
@include btn(dark, $dark, $white);
@include btn(light, $light, $white);

.btn-hoverable {
    background: rgba(0, 0, 0, 0);
    border-radius: 8px;
    transition: background 0.3s ease, transform 0.3s ease;

    &.dark {
        color: $white;
    }

    &.light {
        color: $body-color;
    }

    &:hover,
    &:focus,
    &:active {
        transform: translateY(-2px);
    }

    &:hover.dark {
        background: rgba(255, 255, 255, .1);
    }

    &:hover.light {
        background: rgba(0, 0, 0, .1);
    }
}

.btn {
//   border: none;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
  // border-radius: 8px;
  transition: all 0.3s ease;

    &.dropdown-toggle::before {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .3em;
        vertical-align: middle;
        content: '';
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-left: .3em solid transparent;
    }
}

.btn-link {
    box-shadow: none;
    // color: $info;

    &:hover,
    &:focus {
        box-shadow: none;
        // color: darken($info, 10%);
        text-decoration: underline;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        color: $text-muted;
    }
}

.btn-outline-secondary {
    border-color: $gray-400;
    // color: $gray-600;
}

.btn-warning {
    color: $white;
}

.btn-group {

    .btn+.btn,
    .btn+.btn-group,
    .btn-group+.btn,
    .btn-group+.btn-group {
        margin-left: 0;
    }

    .btn+.btn,
    .btn+.btn-group>.dropdown-toggle {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
    }
}

.btn-group-vertical {

    >.btn+.btn,
    >.btn+.btn-group,
    >.btn-group+.btn,
    >.btn-group+.btn-group {
        margin-top: 0;
    }
}

// Cards =======================================================================
.card {
    background: #ffffff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, .05);
    border-radius: 12px;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
    }
}

.bordered-bottom-card-header {
    border-color: rgba(0, 0, 0, .03);
    background: none;
}

// Table =======================================================================
.pagination {
    box-shadow: 1px 1px 7px -3px #00000047;

    .page-link {
        transition: background-color 0.3s ease;
    }
}

.dropdown {
    display: flex;

    &.open,
    &:hover,
    &:focus {
        background-color: $gray-100;
    }

    *:first-child {
        align-self: center;
    }

    .dropdown-menu {
        top: 100%;
        left: 4rem;
        box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
    }
}

.dropdown-item {
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e9ecef;
    }
}

app-table {
    th {
        font-weight: 400;
        font-variation-settings: 'wght' 400;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(74, 76, 100, 0.03);
    }

    .table th,
    .table td {
        border-top: none;
        border-bottom: none;
    }
}

.circle-button {
    background-color: $white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    border-radius: 50%;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
}

.modal-content {
    border: 0;
}

.text-grey {
    color: #d2d2d2;
}

.mouse-selection-overlay {
    background: rgba(0, 0, 0, .1);
    border: $primary 1px solid;
}

app-searchbar {
    align-self: center;
    height: 40px;
    padding: 0 0 0 2px;
  > .input-group {
    border: none !important;
    > input {
      height: 40px !important;
    }
  }
}

.employee-modal-image-container {
    display: flex;
    max-width: 80%;
    max-height: 50vh;

    >* {
        max-width: 100%;
        max-height: 100%;
    }
}

.cdk-drag-preview {
    opacity: .7;
    z-index: 100500 !important;
}

p.break {
    display: none;
}

app-new-article p.break {
    display: block !important;
    line-height: 0;
}

app-new-article p.break::before {
    display: block !important;
    content: '!!РАЗДЕЛИТЕЛЬ!!';
    color: #4caf50;
}

.container-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.popover {
    background-color: white !important;
    max-width: 100% !important;
}

.popover-body {
    max-width: 100% !important;
}

// Responsive design adjustments
@media (min-width: 576px) {
    .container-small {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .container-small {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .container-small {
        max-width: 780px;
    }
}

@media (min-width: 1200px) {
    .container-small {
        max-width: 960px;
    }
}

.post-editable {
    cursor: pointer;
}

.mb-2rem {
    margin-bottom: 2rem;
}

.row:before,
.row:after {
    display: inline-block;
}

hr {
    opacity: 0.25;
}

.fa-pusher:before {
    content: "\f02d";
    color: transparent !important;
}

.period-picker {
    position: relative;
    width: 100%;
    padding: .5rem;
    display: flex;
    align-items: center;

    input {
        width: 14rem;
        padding-left: 10px;
        border: none;
        outline: none;
    }

    @include media-breakpoint-down(md) {
        input {
            width: 100%;
        }
    }

    i {
        position: absolute;
        right: 1rem;
    }
}

bs-dropdown-container {
    z-index: 1100 !important;
}

//Стили для tooltip, решающие проблему с отображением(bootstrap 5.2)
.popover {
    position: absolute;
    top: 0;
    left: 0 #{"/* rtl:ignore */"};
}

.popover-arrow {
    position: absolute;
}

.tooltip {
    position: absolute;
}

.tooltip-arrow {
    position: absolute;
}

//Убрал новые стили table(bootstrap 5.2) для корректной расцветки таблицы.
.table,
app-gantt-view-component .scrolling-area,
app-percent-tracking-table .scrolling-area {
    --bs-table-bg: none;
    --bs-table-striped-bg: none;
}

.ngx-slider {
    .ngx-slider-selection {
        background: $primary !important;
    }

    .ngx-slider-pointer {
        background: $primary !important;

        &.ngx-slider-active::after {
            background: lighten($primary, 10) !important;
        }
    }
}

.ngx-slider-pointer.ngx-slider-active::after {
    background: lighten($primary, 10) !important;
}

i.emoji {
    font-style: normal;
}

.col-20-percent {
    flex: 0 0 auto;
    width: 20%;
}

// Include additional component styles
@import 'app/modules/employee-modal/employee-modal/employee-modal.component';
@import 'app/modules/shared/searchbar-pagination-wrapper-coupling/index';
@import 'app/modules/shared/employees-widget/employees-widget.component';
@import 'app/modules/shared/news-widget/news-widget.component';
@import 'app/modules/user-photo/user-photo.component';
@import 'app/main-layout/main-layout.component';
@import 'app/pages/login/login.component';
@import 'app/pages/happy-birthday-ilia/happy-birthday-ilia.component';
@import 'app/pages/dashboard/dashboard/dashboard.component';
@import 'app/pages/articles/editor/editor.component';
@import 'app/pages/main/booking/booking-table/booking-table.component';
@import 'app/pages/main/passes/passes-table/passes-table.component';
@import 'app/pages/main/percent-tracking/percent-tracking-table/percent-tracking-table.component';
@import 'app/pages/main/percent-tracking/percent-tracking-page/percent-tracking-page.component';
@import 'app/pages/main/employees-tree-view/employees-tree-view.component';
@import 'app/pages/main/place-booking/old-map/map/map.component';
@import 'app/pages/main/place-booking/place-booking.component';
@import 'app/pages/main/company-structure/company-structure.component';
@import 'app/pages/hr/staff-forms/wrapper/staff-forms.wrapper';
@import 'app/pages/administration/time-tracking/time-tracking-table/time-tracking-table.component';
@import 'app/modules/navigation/sidebar/sidebar.component';

@import 'app/pages/articles/view-article/view-article.component';
@import 'app/pages/articles/feed/feed.component';
@import 'app/pages/sections/pages-list/pages-list.component';

@import 'app/pages/user-profile/user-profile/user-profile.component';
@import 'app/modules/wall/write-post/write-post.component';
@import 'app/modules/wall/controls/contrent-controls.component';
@import 'app/pages/sections/sections-files-table/sections-files-table.component';
@import 'app/pages/sections/files-widget/files-widget.component';
@import 'app/pages/sections/page/layouts/product-page/product-page.layout.scss';

@import 'app/pages/main/vacation-scheduler/vacation-scheduler';

@import 'app/pages/main/service-desk/gifts-applications/index';

@import '../../../shared/modules/forms/index';
@import '../../../shared/modules/forms/month-picker/index';
@import '../../../shared/modules/navigation/index';
@import '../../../shared/modules/searchbar/index';
@import '../../../shared/modules/modal/index';
@import '../../../shared/modules/resizer/index';
@import '../../../shared/modules/table/index';
@import '../../../shared/modules/table/table-with-controls/index';
@import '../../../shared/modules/forms/text-editor/text-editor.component';
@import '../../../shared/modules/date-selector/date-selector.component';
