.router-container app-percent-tracking-page {
  flex-direction: row !important;
  overflow: hidden;

  app-drawer .hy-drawer-content {
    @extend .shadow-sm;

    .inner {
      margin: 1rem;
      display: flex;
      flex-direction: column;
      height: calc(100% - #{$navbar-height} - 5rem);

      ng-select.form-control {
        margin-bottom: 1rem;
        min-height: $input-height;
        height: auto;
      }
    }
  }

  .lock {
    // @extend .ms-3;
    @extend .mb-0;
    cursor: pointer;

    span {
      @extend .ms-2;
    }
  }
}
