$scroll-border-color: #fff !default;

::-webkit-scrollbar {
  background: transparent;
  overflow: visible;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #adadad;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  border: solid $scroll-border-color;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

::-webkit-scrollbar-thumb:horizontal {
  border-width: 4px 6px;
  min-width: 40px;
}

::-webkit-scrollbar-thumb:vertical {
  border-width: 6px 4px;
  min-height: 40px;
}

::-webkit-scrollbar-track-piece {
  background-color: $scroll-border-color;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
