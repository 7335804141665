app-employees-widget {
    .person-container {
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        z-index: 100;
        cursor: pointer;
    }

    .slick-arrow {
        display: none !important;
    }

    app-user-photo {
        width: 100% !important;
        height: auto !important;
        //width: 68px !important;
        //height: 68px !important;
        //img {
        //    width: 68px !important;
        //    height: 68px !important;
        //}
    }
}
