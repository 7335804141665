app-month-picker {
  @extend .border;
  @extend .rounded;
  display: block;
  position: relative;
  background-color: white;

  .trigger {
    cursor: pointer;
    text-align: center;
  }
}

.month-picker .owl-dt-calendar-control-content {
  pointer-events: none;

  .owl-dt-control-button-arrow {
    display: none;
  }
}
