app-sidebar {
  z-index: 20;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, .1);
  @import '../../../../../../../shared/styles/scrollbar';

  .hy-drawer-content {
    overflow: overlay;
  }

  .hy-drawer-scrim {
    background: rgba(0, 0, 0, .5);
  }

  app-sidebar-menu-item app-sidebar-menu-item li a {
    padding-left: .3rem;
  }
}

.logo {
  @extend .d-flex;
  @extend .align-items-center;
//   @extend .navbar-height;

  img {
    @extend .d-block;
    @extend .mx-auto;
  }
}

@include media-breakpoint-down(lg) {
  app-sidebar {
    z-index: 300;
  }
}
