app-text-editor {
  .panel {
    position: sticky;
    top: 0;
    z-index: 1000;
    border-radius: 2px;

    .text-editor-panel-of-button {
      height: auto;
      display: flex;
      flex-grow: 1;
      align-items: center;

      .editor-control-buttons-group {
        @extend .btn-group;
        @extend .border-light;
        @extend .ms-2;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;

        button {
          @extend .btn;
          @extend .btn-light;
        }

      }

      &.panel-disabled .editor-control-buttons-group button:not(.keep-enabled) {
        @extend .disabled;
      }

      .picker-container {
        position: absolute;
        top: 3rem !important;
        left: auto !important;
        right: 0 !important;

        .swatch {
          @extend .border;
        }
      }
    }
  }

  .content {
    color: #000000;
    min-height: 30rem;

    &:focus {
      border: 1px solid $primary;
    }
  }

  .col {
    margin: .3rem;
    border: 1px #13294b dashed;
  }

  img {
    position: relative;
    max-width: 100%;

    &:hover {
      box-shadow: 0 0 10px 5px rgba(19, 41, 75, 0.8);
    }
  }

  .choosen-element {
    box-shadow: 0 0 15px 5px rgba(19, 41, 75, 0.8);
  }
}

#buttonsOfTable,
#buttonsOfGrid {

  .cell {
    width: 1rem;
    height: 1rem;
    border: 1px #13294b dashed;
    margin: 0.1rem;
  }

  .active-cell {
    border: 2px solid !important;
  }
}
