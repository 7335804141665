$switch-margin: .3rem;
$circle-height-width: 1.5rem;
$oval-height: 1.5rem;
$oval-width: 3.688rem;
$oval-transition: all .3s ease-in-out;
$circle-padding: ($circle-height-width - $oval-height) * 0.5;

$switch-box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .25) !default;

app-switch {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
    color: #666;
    font-weight: 500;
  }
  white-space: nowrap;

  input[type='checkbox'] {
    display: none;
  }

  &.animated-switch {
    label,
    label::before,
    label::after {
      transition: $oval-transition;
    }
  }

  input[type='checkbox'] + label {
    cursor: pointer;
    height: 0;
    position: relative;
    width: $oval-width;
    margin-left: $circle-padding + $switch-margin;
    margin-right: $switch-margin;

    &::before,
    &::after {
      position: absolute;
      margin-top: -$oval-height * 0.5;
    }

    &::before {
      border: 1px solid #e5e5e5;
      border-radius: $oval-height * 0.5;
      content: '';
      height: $oval-height;
      width: $oval-width;
    }

    &::after {
      background: rgb(255, 255, 255);
      border-radius: $circle-height-width * 0.5;
      box-shadow: $switch-box-shadow;
      content: '';
      height: $circle-height-width;
      width: $circle-height-width;
      left: -$circle-padding;
      top: -$circle-padding;
    }
  }

  input[type='checkbox']:disabled + label::before {
    background: #e5e5e5 !important;
  }

  input[type='checkbox']:disabled + label::after {
    background: #c6c6c6 !important;
  }

  input[type='checkbox']:checked + label::after {
    left: $oval-width - $circle-height-width + $circle-padding;
  }
}

app-switch.toggle-switch {
  label.not-selected {
    color: #e5e5e5;
  }

  &.switch-centered {
    .left-label,
    .right-label {
      width: 0;
      overflow-wrap: initial;
      white-space: nowrap;
    }

    .left-label {
      direction: rtl;
    }
  }
}

@each $color, $value in $theme-colors {
  .switch-#{$color} {
    > input[type='checkbox'] + label {
      &::after {
        background: $value;
      }
    }

    &:not(.toggle-switch) > input[type='checkbox']:checked + label {
      &::before {
        background: rgba($value, .4);
      }
    }
  }
}
