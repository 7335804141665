app-employee-modal {
  i {
    @extend .text-grey;
    @extend .me-1;
  }

  .row {
    margin: 1.5rem;
  }

  app-user-photo {
    border-radius: 100vh;
    width: 100%;
    max-width: 300px;
    overflow: hidden;
  }

  dt,
  .position {
    font-size: .9rem;
  }

  dd {
    margin-bottom: .3rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  dl {
    margin-bottom: 0;
  }

  .info,
  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

@media (min-width: 1200px) {
  .modal-employee {
    max-width: 850px;
  }
}

@media (min-width: 992px) {
  .modal-employee {
    max-width: 850px;
  }
}
