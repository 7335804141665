app-user-photo {
    display: flex;
    align-self: center;
    background-image: url('/assets/images/loading.gif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100vh;
    .wrapper {
        display: flex;
        width: 100%;
        padding-top: 100%;
        cursor: pointer;
        position: relative;

        img {
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity .3s ease-in;
            pointer-events: none;
            will-change: transform;
            image-rendering: -webkit-optimize-contrast;
        }
    }

    &.error {
        background-image: url('/assets/images/no-image.png');
        opacity: 0.25;
        //background-image: none;
        img {
            display: none;
        }
    }

    &.user-photo-small {

        width: 3rem;
        height: 3rem;
        overflow: hidden;
        pointer-events: none;
    }

    &.user-photo-xs {
        border-radius: 100vh;
        width: 2rem;
        height: 2rem;
        overflow: hidden;
        pointer-events: none;
    }
}
