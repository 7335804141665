app-percent-tracking-table {
  @extend .shadow-sm;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 1px;

  app-table {
    @extend .sticky-column;
    @extend .sticky-footer;

    td,
    th {
      text-align: center;

      &:first-child {
        min-width: 20rem;
      }
    }
  }

  .custom-btn-group {
    .btn {
        max-width: 10rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
  }

  app-select-cell {
    display: flex;
    align-items: center;

    &.is-invalid {
      ng-select {
        border-color: $danger;
      }
    }

    &.has-media-type {
      ng-select:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        margin-top: -1px;
      }

      ng-select:not(:last-child) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .invalid-feedback {
      display: block;
    }
  }

  app-employment-tracking-percent-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .percent-input {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:hover,
      &:focus {
        -moz-appearance: number-input;
      }
    }

    .comment {
      @extend .card;
      @extend .card-body;
      @extend .position-absolute;
      left: 70%;
      top: 50%;
      width: 11.3rem;
      transform: translateY(-50%);
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        left: -.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: .7rem .5rem .7rem 0;
        border-color: transparent $white transparent transparent;
      }

      textarea {
        height: 5rem;
      }
    }

    &.predefined::after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .1);
    }

    &.commented::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 23px 23px;
      border-color: transparent transparent $warning transparent;
    }

    &.comment-is-required::before {
      content: '!';
      position: absolute;
      bottom: 35px;
      right: 10px;
      width: 0;
      height: 0;
      color: $danger;
      font-weight: 700;
    }
  }

  app-employment-tracking-change-activity-cell {
    @extend .animated;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.predefined {
      background: $success;
      color: white;
    }
  }

  .no-edit {
    app-select-cell {
      .ng-arrow-wrapper {
        opacity: 0;
      }
    }

    app-employment-tracking-percent-cell {
      pointer-events: none;
      cursor: auto;

      .fa-edit {
        opacity: 0;
      }
    }
  }


  $col-cell-width: 150px;
  $row-cell-height: 180px;

  $col-height: 75px;

  $footer-height: 2rem;

  .scrolling-area {
    @extend table;
    @extend .table;
    @extend .mb-0;

    .scrollable-content {
      width: auto;
      height: auto;
    }

    flex-grow: 1;
    height: 1px;
    contain: strict;
    transform: translateZ(0);
    will-change: scroll-position;

    .header-decoration {
      background: #fff;
      box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);
      border: 0;
    }

    .vertical-scroller {
      margin: 0 auto;
      //box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);

      > .scrollable-content {
        display: flex;
      }
    }

      .horizontal-scroller {
        box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);
        padding: 0;
      }

    .col-header,
    .cell {
      width: $col-cell-width;
    }

    .row-header,
    .cell {
      height: $row-cell-height;
      position: relative;

      > * {
        width: 100%;
        height: 100%;
      }
    }

    .col-header,
    .row-header,
    .footer-cell {
      @extend th;
      @extend .header-decoration;
    }

    .footer-cell {
      position: sticky;
      bottom: 0;
      height: $footer-height;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .col-header {
      position: sticky;
      top: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      height: $col-height;
    }

    .row-header {
      width: 100%;

      > * {
        padding: 0 .75rem;
      }
    }

    .row-headers-container {
      position: sticky;
      left: 0;
      z-index: 4;
        padding: 0;

      .fill-cell {
        @extend .header-decoration;
        transform: translate(0) !important;
        position: sticky;
        top: 0;
        z-index: 1;
        height: $col-height;
        width: 100%;
      }
    }

    .cell {
      &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
      }

      width: $col-cell-width;
      height: $row-cell-height;
    }
  }

  .btn {
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
}
