.formly-row {
  @extend .row;

  > formly-field {
    @extend .col;
  }
}

.input-group-text {
  &.fa,
  &.fab {
    font-weight: 900;
  }
}

.ng-select .ng-spinner-loader {
  margin-top: 10px !important;
}


@import './label-wrapper/label-wrapper.component';
@import './switch/index';
@import './three-state-switch/three-state-switch.component';
@import './four-state-switch/four-state-switch.component';
