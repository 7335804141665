app-company-structure {
  h1, h2, h3 {
    font-weight: bold;
    letter-spacing: 1px;
  }

  .logo-icon {
    width: 70px;
  }

  .card-grey {
    background-color: #f0f4f8;
    box-shadow: 0 0 15px 5px rgba(34, 60, 80, 0.1) !important;
  }

  .card {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  .structure-container {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1fr;
    @media (max-width: 768px) {
      height: unset;
      grid-template-columns: fit-content(100%);
    }
    gap: 10px;
  }

  .card {
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }

  .logo-icon {
    transition: transform 0.2s ease-in-out;
  }

  .logo-icon:hover {
    transform: scale(1.1);
  }

  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  @media (max-width: 768px) {
    .structure-container {
      zoom: 1;
    }

    app-partners-mi-card img {
      width: 100px;
      height: 100px;
    }

    h1, h2, h3 {
      font-size: 1.5rem;
    }
  }

  .structure-container {
    zoom: 0.8;

    .substructures-container {

      .title {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .card-grey {
        background-color: #f9f9f9;
        box-shadow: 0 0 5px 3px rgba(34, 60, 80, 0.2) !important;
      }

      .big {
        height: 888px;
      }

      .large {
        height: 442px;
      }

      .large-custom {
        height: 584px;
      }

      .middle {
        height: 291px;
      }

      .mini {
        height: 216px;
        position: relative;

        h3 {
          position: absolute;
          top: 5px;
          right: 10px;
        }
      }

      .extra-mini {
        height: 171px;
      }

      .xextra-mini {
        height: 144px;
      }

      hr {
        opacity: 1;
      }
    }
  }


  app-partners-mi-card {
    img {
      width: 145px;
      height: 180px;
    }

    .card {
      width: 355px;
      background-color: #f9f9f9;
      box-shadow: 0 0 5px 3px rgba(34, 60, 80, 0.2) !important;
    }
  }
}
