app-point-info {
  @extend .card;
  @extend .card-body;
  @extend .animated;

  min-height: auto;

  &.selected {
    order: -1;
  }

  .larger-icon {
    font-size: rfs-fluid-value(1.5rem);
  }
}
