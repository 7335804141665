$label-padding: 5px;

app-label-wrapper {
  .label-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;

    > *:first-child {
      flex-grow: 1;
      width: 100%;

      &[typed],
      &[focused],
      &[autofilled] {
        // stylelint-disable scss/selector-no-redundant-nesting-selector
        & ~ .label-inner-wrapper {
          // stylelint-enable
          @extend .label-opened;
        }
      }
    }
  }

  .label-inner-wrapper {
    width: 0;
    z-index: 30;
    display: flex;
    align-items: stretch;
    transition: opacity .1s ease-in, transform .1s ease-in;
    opacity: .7;

    label {
      margin-bottom: 0 !important;
      transform: translateX(1rem);
      position: relative;
      pointer-events: none;
      white-space: nowrap;
      display: flex;
      align-items: center;

      &::before {
        transition: all .1s ease-in;
        z-index: -1;
        content: '';
        position: absolute;
        display: block;
        left: rfs-fluid-value(rfs-value(-$label-padding));
        width: 0;
        height: 100%;
        background-color: white;
      }
    }
  }

  .label-opened {
    transform: translateY(-50%) scale(.8);
    opacity: 1;

    label::before {
      width: calc(100% + rfs-fluid-value(rfs-value(#{$label-padding * 2})));
      height: 3px;
      box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
    }
  }


  /** Autofill issues **/
  // stylelint-disable block-no-empty
  @keyframes onAutoFillStart {
    from {}
    to {}
  }

  *:-webkit-autofill {
    // Expose a hook for JavaScript when auto fill is shown.
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillStart;
    // Make the backgound color become yellow _really slowly_
    transition: background-color 5000000s ease-in-out 0s;
  }

  .input-group-append {
    .input-group-text {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
