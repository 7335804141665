@media screen {
  .hy-drawer-scrim {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    pointer-events: none;
    transform: translateX(0);
    -webkit-tap-highlight-color: transparent;
  }

  .hy-drawer-content {
    position: fixed;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(0);
    contain: strict;
    -webkit-overflow-scrolling: touch;
    z-index: 30;
  }

  .hy-drawer-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
  }

  .hy-drawer-grabbing {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }
}

@media print {
  .hy-drawer-scrim {
    display: none !important;
  }

  .hy-drawer-content {
    transform: none !important;
  }
}
