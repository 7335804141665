/* height */
@use "sass:math";

$h: 24px;
/* width */
$w: $h * 6;
/* toggle dimensions */
$tw: $h;
/* font size */
$fs: 1rem;
/* toggle's border thickness */
$bt: 0.1rem;
/* toggle box shadow */
$bs: $bt * 2;
/* labels animation time */
$ta: 6s;
/* label text color */
$text-color: white;

/* colors: si, ?, no*/
$colors: #0271bc, #0271bc, #0271bc;

$switch-margin: .3rem;
$circle-height-width: 1.5rem;
$oval-height: 1.5rem;
$oval-width: 3.688rem;
$oval-transition: all .3s ease-in-out;
$circle-padding: ($circle-height-width - $oval-height) * 0.5;

$switch-box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .25) !default;

@mixin borderColor($i) {
  border: $bt solid nth($colors, $i);
  background: nth($colors, $i);
  //box-shadow: 0 0 $bs $bs/2 nth($colors, $i) inset,0 0 $bs $bs/2 nth($colors, $i);
}

@mixin animationDelay($time) {
  -webkit-animation-delay: $time;
  -o-animation-delay: $time;
  -moz-animation-delay: $time;
  animation-delay: $time;
}

@mixin animation($time) {
  -webkit-animation: $time rot-label ease-in-out infinite;
  -moz-animation: $time rot-label ease-in-out infinite;
  -o-animation: $time rot-label ease-in-out infinite;
  animation: $time rot-label ease-in-out infinite;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{ $name } {
        @content
      };
      @-o-keyframes #{ $name } {
        @content
      };
      @-moz-keyframes #{ $name } {
        @content
      };
      @keyframes #{ $name } {
        @content
      }
}

@include keyframes(rot-label) {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(-400deg);
  }
  18% {
    transform: rotate(-340deg);
  }
  20% {
    transform: rotate(-360deg);
  }
  30% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

app-three-state-switch {
  .wrapper {
    width: $w;
    height: $h;
    position: relative;
    margin: 0 auto;
    border-radius: $h*0.5;
    //background: gray;
    border: 1px solid #e5e5e5;
    background: rgb(255, 255, 255);


    & .toggle {
      //width: $tw;
      //height: $tw;
      position: absolute;
      left: 41.333333%;
      top: -($tw - $h) * 0.5 - 1;
      //border-radius: 50%;
      //box-sizing: border-box;
      //@include borderColor(2);

      background: rgb(255, 255, 255);
      border-radius: $circle-height-width * 0.5;
      box-shadow: $switch-box-shadow;
      content: '';
      height: $circle-height-width;
      width: $circle-height-width;
      //left: -$circle-padding;
      //top: -$circle-padding;

      transition: all .3s cubic-bezier(
                      0.175, 0.885, 0.320, 1.275
      );
    }

    & label {
      color: #e5e5e5;
      font-weight: 500;
      cursor: pointer;
      width: $h;
      height: $h;
      position: absolute;
      margin: 0;
      padding: 0;
      z-index: 1;
      display: inline-block;

      text-align: center;
      line-height: $h;
      text-transform: uppercase;
      font-size: rfs-fluid-value($fs);
      @include animation($ta);
    }

    & input {
      position: absolute;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }

    input:nth-child(2):checked,
    .first-label.text-white {

      ~ .toggle {
        @include borderColor(1);
        left: 0;
        width: 42px;
        border-radius: 12px;
      }
    }

    input:nth-child(4):checked,
    .second-label.text-white {
      ~ .toggle {
        @include borderColor(2);
        left: 56px;
      }
    }

    input:nth-child(6):checked,
    .third-label.text-white {

      ~ .toggle {
        @include borderColor(3);
        width: 35px;
        left: 112px;
      }
    }

    .first-label {
      width: 42px !important;
    }

    .second-label {
      left: 56px;
    }

    .third-label {
      left: 112px;
      width: 35px !important;
    }

    .second-label {
      @include animationDelay(math.div($ta, 3));
    }

    .third-label {
      @include animationDelay(math.div($ta * 2, 3));
    }
  }
}
