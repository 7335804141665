app-articles-feed {

  .categories {
    position: sticky;
    top: 1rem;


    > h6 {
      border-radius: 0.5rem;
      background-color: #FFFFFF;
    }

    h6 {
      border-radius: 0.5rem;
      transition-duration: 300ms;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);

        span.badge {
          background-color: rgba(19, 41, 75, 0.7);
        }
      }

      &.selected {
        background-color: #FFFFFF;
        font-weight: bold !important;

        span.badge {
          background-color: #13294b;
        }
      }

      span.badge {
        background-color: rgba(19, 41, 75, 0.4);
      }

      i {
        transition-duration: 300ms;

        &.animation {
          transform: rotate(180deg)
        }
      }
    }


  }

}
