app-pagination-wrapper:not(.pristine):not(.has-items):not(.loading) {
  &::after {
    @extend .centered;
    @extend .h3;
  }

  &[data-searchstring]::after {
    content: 'По запросу "' attr(data-searchstring) '" ничего не найдено';
    text-align: center;
  }

  &:not([data-searchstring])::after {
    content: 'Нет данных для отображения';
    text-align: center;
  }
}
