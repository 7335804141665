
app-booking {
    @extend .sticky-column;

    app-table {
        @include media-breakpoint-up(xl) {
            table {
                table-layout: fixed;
            }
        }

        tr > * {
            text-align: center !important;

            &:not(:last-of-type) {
                border-right: 1px solid rgba(0, 0, 0, .1);
            }

            &:first-of-type {
                width: 10rem;
            }
        }

        thead tr > * span {
            white-space: pre !important;
            font-family: $font-family-monospace;
        }

        thead tr > * span,
        app-time-from-time-to-cell b {
            font-variation-settings: 'wght' 400;
        }

        tbody tr > * :not(:first-of-type) {
            width: 1px;
        }

        table td {
            @media (max-width: 768px) {
                min-width: 7rem;
            }
        }

        .booking-cell-busy,
        .booking-cell-selected {
            color: white;
        }

        .booking-cell-busy {
            @extend .shadow-sm;
            position: relative;
            background: #a9a9a9;
            border-color: darken(#a9a9a9, 5) !important;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: black;
                opacity: 0;
                transition: opacity .3s ease-in;
            }

            &:hover::after {
                opacity: .15;
            }

            &.cancel-available:hover  {
                .booking-cell-delete {
                    display: block;
                }
            }

            &.own {
                background: lighten($primary, 20) !important;
                border-color: lighten($primary, 30) !important;
                .booking-cell-delete {
                    color: $danger !important;
                }
            }

            .booking-cell-delete {

                position: absolute;
                top: 3%;
                right: 3%;
                cursor: pointer;
                display: none;
                color: #800020;
                z-index: 100;
            }

        }

        .booking-cell-selected {
            @extend .bg-success;
            @extend .border-success;
        }
    }
}

.booking-date-selector {
    @extend .input-group;
    @extend .iconed;

    @media screen and (min-device-width: 960px) {
        max-width: 200px;
    }


    input {
        padding-right: 3em;
        @media (max-width: 768px) {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem;
        }
    }

    .date-change {
        z-index: 3;
        align-self: center;
        position: absolute;
        right: .25rem;

        > div {
            display: inline-block;
            cursor: pointer;
        }

        i:first-of-type {
            margin-right: .3em;
        }
    }
}
