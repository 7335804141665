
.open .card .content * {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
}

.open .article-title {
  font-family: 'Montserrat', sans-serif !important;
}

.open .article-title * {
  font-family: 'Montserrat', sans-serif !important;
}


.roboto .card .content * {
  font-family: 'Roboto', sans-serif !important;
}

.roboto .article-title {
  font-family: 'Noto Sans', sans-serif !important;
}

.roboto .article-title * {
  font-family: 'Noto Sans', sans-serif !important;
}

