svg.point {
  font-family: 'DINPro', sans-serif;

  g.inner-group {
    transform-origin: center;
    transition: all .3s ease-in;
    cursor: pointer;
  }

  &.highlighted:not(.selected) {
    g {
      fill: $primary !important;
      g {
        .booking-marker {
          fill: white !important;
        }
      }
    }

    @at-root .point-search-applied .point-search-match {
      @extend .highlighted;
    }
  }

  &.lighten-color {
    g {
      opacity: .7;
    }
  }

  &.selected {
    g {
      fill: $primary;
    }
  }

  .line {
    position: absolute;
    content: '';
    display: block;
    border: 1px solid #1eaeff;
    top: 50%;
    left: 50%;
    opacity: .7;

    &.horizontal {
      transform: scaleX(3);
      width: 100vh;
    }

    &.vertical {
      transform: scaleY(3);
      height: 100vh;
    }
  }

  .point:hover ~ .line {
    opacity: 1;
  }
}
