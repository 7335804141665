@import 'shared/modules/modal/wizard-modal/wizard-form';

app-staff-forms-wrapper {
  router-outlet ~ * {
    top: 0;
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
  }

  app-staff-form {
    @include wizard-from();
  }
}
