app-time-tracking-table {
  @extend .sticky-column;

  app-table {
    table {
      @extend .table-bordered;
      border-top: 0 !important;
    }

    tr > * {
      &:not(:first-child) {
        text-align: center !important;
        white-space: nowrap;
      }
    }

    th {
      border: 0 !important;
    }
  }
}
